<template>
  <div class="box-company-info">
    <div>My Company Information</div>
    <div class="box-group">
      <!-- 公司名称 -->
      <label for="companyname" class="control-label">Company Name <span class="require">*</span></label>
      <div>
        <input id="companyname" v-model="firmInfo.CompanyName" type="text" class="form-control" :placeholder="placeholder.CompanyName" disabled>
        <span style="color:red">
          <span>{{ checkField.companynameinfo }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- website -->
      <label for="website" class="control-label">Website </label>
      <div>
        <input v-model="firmInfo.CompanyWebSite" type="text" class="form-control" id="website"
               :placeholder="placeholder.Website"
               :disabled="isFormDisabled">
      </div>
    </div>
    <!-- Business Activity -->
    <div class="box-group">
      <label class="control-label">
        Business Activity
        <span class="require">*</span><br/>
        <span style="font-size: 12px">
          (Multiple Choices Allowed)
        </span>
      </label>
      <div style="height:198px;width: 489px;margin-left: 20px;">
        <div class="form-control" style="width:100%;height:100%;border: 1px solid #dbdbdb;">
          <div style="padding:0">
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_cattlefarm" type="checkbox" :disabled="isFormDisabled">Cattle Farm
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_cattleslaughterhouse" type="checkbox" :disabled="isFormDisabled">Beef
              Slaughter/Processing Plant
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_beefexporter" type="checkbox" :disabled="isFormDisabled">Beef
              Exporter to China
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_beefimporter" type="checkbox" :disabled="isFormDisabled">Chinese Beef
              Importer
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_beefdistributer" type="checkbox" :disabled="isFormDisabled">Chinese
              Beef Wholesaler/Trader
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_beefproductproducer" type="checkbox" :disabled="isFormDisabled">Chinese
              Beef Products
              Manufacturer
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_restaurant" type="checkbox" :disabled="isFormDisabled">Chinese Beef
              Retailer
              (Restaurant/Supermarket)
            </div>
            <div class="checkbox-style">
              <input v-model="CompanyType" value="_other" type="checkbox" class="checkbox-style"
                     :disabled="isFormDisabled">Other
            </div>
          </div>
        </div>
        <span style="color:red">
          <span>{{ checkField.companytypeinfo }}</span>
        </span>
      </div>
    </div>
    <!-- Country -->
    <div class="box-group">
      <label for="country" class="control-label">Country <span class="require">*</span></label>
      <div style="width: 489px;display: flex;justify-content: flex-start;margin-left: 20px">
        <div>
          <select v-model="firmInfo.CompanyCountry"
                  class="form-control"
                  id="country"
                  :disabled="isFormDisabled">
            <option v-for="country in countrydata" :key="country" class="form-control" :value="country">
              {{ $t(`message["${country}"]`) }}
            </option>
          </select>
          <span style="color:red">
            <span>{{ checkField.countryinfo }}</span>
          </span>
        </div>
        <!--Province/State-->
        <div style="display: flex;flex-direction: column">
          <div style="display: flex">
            <label for="province" class="control-label"
                   style="width:150px!important;margin-right: 20px;text-align: right">Province/State <span
              class="require">*</span></label>
            <select v-model="firmInfo.CompanyProvince"
                    class="form-control"
                    id="province"
                    :disabled="isFormDisabled">
              <option v-for="province in provincelist" :key="province" :value="province">
                {{ $t(`message["${province}"]`) }}
              </option>
            </select>
            <span style="color:red">
              <span>{{ checkField.provinceinfo }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- Contact Person -->
    <div class="box-group">
      <label for="contactpersonname" class="control-label">Contact Person <span class="require">*</span></label>
      <div style="width: 489px;display: flex;justify-content: flex-start;margin-left: 20px">
        <div>
          <input style="width: 160px!important;margin-left: 0" v-model="firmInfo.ContactPersonName" type="text"
                 class="form-control" id="contactpersonname" :disabled="isFormDisabled" @blur="checkContact" @input="checkContact" :class="{ error : checkField.contactpersonnameinfo }">
          <span style="color:red; font-size: 12px">
            <span>{{ checkField.contactpersonnameinfo }}</span>
          </span>
        </div>
        <!--Gender-->
        <div style="display: flex;flex-direction: column">
          <div style="display: flex">
            <label for="contactpersonsex" class="control-label"
                   style="width:150px!important;margin-right: 20px;text-align: right">Gender <span
              class="require">*</span></label>
            <select v-model="firmInfo.ContactPersonSex"
                    class="form-control"
                    id="contactpersonsex" :disabled="isFormDisabled">
              <option value="_mr">Mr</option>
              <option value="_ms">Ms</option>
            </select>
            <span style="color:red">
              <span>{{ checkField.contactpersonsexinfo }}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="box-group">
      <!-- Mobile -->
      <label for="companyphone" class="control-label">Mobile <span class="require">*</span></label>
      <div>
        <input v-model="firmInfo.CompanyPhoneNumber" type="text" class="form-control" id="companyphone" :disabled="isFormDisabled" :placeholder="placeholder.Mobile" @blur="checkPhone" @input="checkPhone"  :class="{ error : checkField.companyphoneinfo }">
        <span style="color:red;font-size: 12px;margin-left: 20px">
          <span>{{ checkField.companyphoneinfo }}</span>
        </span>
      </div>
    </div>
    <div class="box-group">
      <!-- Tel -->
      <label for="cellphone" class="control-label">Tel </label>
      <div>
        <input v-model="firmInfo.ContactPersonCellPhoneNumber" type="text" class="form-control" id="cellphone"
               :placeholder="placeholder.Tel"
               :disabled="isFormDisabled">
      </div>
    </div>
    <div class="box-group">
      <!-- Email -->
      <label for="companyemail" class="control-label">Email <span class="require">*</span></label>
      <div>
        <input v-model="firmInfo.CompanyEmail" type="text" class="form-control" id="companyemail" :disabled="isFormDisabled" :placeholder="placeholder.Email" @blur="checkEmail" @input="checkEmail" :class="{ error : checkField.companyemailinfo }">
        <span style="color:red; font-size: 12px;margin-left: 20px">
          {{checkField.companyemailinfo}}
          <span>{{ checkField.companyemailinfo }}</span>
        </span>
      </div>
    </div>
    <div class="box-group" style="margin-top: 20px;">
      <label/>
      <button v-if="isFormDisabled" type="button" class="btn btn-one" @click="isFormDisabled=false">Update your company information</button>
      <button v-if="!isFormDisabled" type="button" class="btn btn-two" @click.prevent="updateData">Save</button>
      <button v-if="!isFormDisabled" type="button" class="btn btn-three" @click="butCancel">Cancel</button>
    </div>

    <common-dialog id="companyInfoDialog" :message="dialogmsg"/>
  </div>
</template>

<script>
import CommonDialog from '@/components/CommonDialog'
import { countrydata, provincedata } from '@/utils/dictionary'
import { getLocalStore } from '@/utils/common'

export default {
  components: {
    CommonDialog
  },
  name: 'CompanyInfo',
  data () {
    return {
      countrydata: countrydata,
      firmInfo: {
        CompanyType: '',
        ProductType: ''
      },
      checkField: {
        password: '',
        newpassword: '',
        renewpassword: '',
        contactpersonnameinfo: '',
        companyemailinfo: '',
        companyphoneinfo: ''
      },
      CompanyType: [],
      ProductType: [],
      isFormDisabled: true,
      dialogmsg: '',
      placeholder: {
        CompanyName: 'Enter your company name',
        Website: 'Enter your company\'s websit',
        Mobile: 'Enter your company\'s  mobile',
        Tel: 'Enter your company\'s telephone number',
        Email: 'Enter your company\'s  E-mail'
      }
    }
  },
  computed: {
    provincelist () {
      const province = this.firmInfo.CompanyCountry
      if (province && province !== '_othercountry') {
        return provincedata[province].split('|')
      }
      return []
    }
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    butCancel () {
      this.$store.dispatch('GetUserInfo', getLocalStore().UserID).then(() => {
        this.firmInfo = this.$store.getters.userInfo
        this.CompanyType = this.firmInfo.CompanyType.split('|')
      }).catch(() => {
      })
      this.isFormDisabled = true
      this.checkField = {
        password: '',
        newpassword: '',
        renewpassword: '',
        contactpersonnameinfo: '',
        companyemailinfo: '',
        companyphoneinfo: ''
      }
    },
    getUserInfo () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetUserInfo', getLocalStore().UserID).then(() => {
        this.firmInfo = this.$store.getters.userInfo
        this.CompanyType = this.firmInfo.CompanyType.split('|')
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    updateData () {
      if (this.checkForm()) {
        this.$store.dispatch('SetLoadingStatus', true)
        this.firmInfo.userid = getLocalStore().UserID
        this.firmInfo.CompanyType = this.CompanyType.join('|')
        this.firmInfo.ProductType = this.ProductType.join('|')
        this.$store.dispatch('UpdateUserInfo', this.firmInfo).then(() => {
          this.dialogmsg = 'savedsuccessfully'
          this.$store.dispatch('SetLoadingStatus', false)
          this.$bvModal.show('companyInfoDialog')
          this.isFormDisabled = true
        }).catch(() => {
          this.$store.dispatch('SetLoadingStatus', false)
          this.isFormDisabled = true
        })
      }
    },
    checkContact () {
      if (!this.firmInfo.ContactPersonName || !this.firmInfo.ContactPersonName.replace(/\s*/g, '')) {
        this.checkField.contactpersonnameinfo = 'The contact person cannot be empty'
        return false
      } else {
        this.checkField.contactpersonnameinfo = ''
        return true
      }
    },
    checkPhone () {
      if (!this.firmInfo.CompanyPhoneNumber) {
        this.checkField.companyphoneinfo = 'Please enter your companyphone.'
        return false
      } else if (/^[\u4e00-\u9fa5_a-zA-Z]+$/.test(this.firmInfo.CompanyPhoneNumber)) {
        this.checkField.companyphoneinfo = 'Incorrect Mobile Format.'
        return false
      } else {
        this.checkField.companyphoneinfo = ''
        return true
      }
    },
    checkEmail () {
      if (!this.firmInfo.CompanyEmail) {
        this.checkField.companyemailinfo = 'Please enter your email.'
        return false
      } else if (!/(\S)+[@]{1}(\S)+[.]{1}(\w)+/.test(this.firmInfo.CompanyEmail)) {
        this.checkField.companyemailinfo = 'Incorrect email format'
        return false
      } else {
        this.checkField.companyemailinfo = ''
        return true
      }
    },
    checkForm () {
      return this.checkContact() && this.checkPhone() && this.checkEmail()
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-margin-right: 20px;

.box-company-info {
  width: 963px;
  min-height: 744px;
  background-color: #ffffff;
  border-radius: 20px;
  color: #535353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px  0 20px 30px;
}

.box-company-info > div:first-child {
  margin: 40px 0 20px 0;
  font-size: 20px;
}

.box-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 15px;
}

.box-group > label {
  width: 160px;
  font-size: 16px;
  text-align: right;
}

input {
  width: 488px;
  height: 35px;
  margin-left: $cell-margin-right;
  background-color: #EDEDED;
}

select {
  width: 160px;
}

.checkbox-style {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  margin-top: 4px;
}

.checkbox-style > input {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px solid #707070;
  margin-right: 5px;
  margin-left: 0;
}

.btn-one {
  width: 492px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: $cell-margin-right;
}

.btn-two {
  width: 295px;
  height: 35px;
  border-radius: 8px;
  background-color: #4285F4;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: $cell-margin-right;
}

.btn-three {
  width: 168px;
  height: 35px;
  border-radius: 8px;
  background-color: #2054A9;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  margin-left: 29px;
}

.form-control {
  background-color: #EDEDED;
}

.require {
  color: red;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
