<template>
  <div class="box-company-info">
    <div class="header">
      <div>Inbox<div/></div>
    </div>
    <div class="body">
      <div style="width: 40%">
        <div v-if="ChatQueue.length !== 0" class="body-left">
          <div class="hand" v-for="(item, index) in ChatQueue" :key="index" @click="handleChat(item)">
            <div style="display: flex;align-items: center;justify-content: center;width: 85px;">
              <img v-if="item.IsTo" style="width: 82px;height: 71px" :src="require(`@/assets/image/market/company/${item.Picture}.png`)" alt="">
              <img v-if="!item.IsTo" style="width: 43px;height: 41px" :src="require(`@/assets/image/market/companyLogo/${item.Picture}.png`)" alt="">
            </div>
            <div style="max-width: 299px" :class="{'red-point' : item.IsRedDot}">{{ item.CompanyNameEn }}</div>
          </div>
        </div>
        <div v-if="ChatQueue.length === 0" class="no-message">
          No message
        </div>
      </div>
      <div style="flex: 1">
        <div  v-if="this.ChatItem.CompanyName !== ''" class="body-right">
          <img style="width: 100%;height: 74px" src="@/assets/image/market/btcmessage.png" alt="">
          <div class="body-right-body">
            <div class="item1">
              <img v-if="ChatItem.IsTo" style="width: 60px;height: 50px" :src="require(`@/assets/image/market/company/${ChatItem.Picture}.png`)" alt="">
              <img v-if="!ChatItem.IsTo" style="width: 43px;height: 41px" :src="require(`@/assets/image/market/companyLogo/${ChatItem.Picture}.png`)" alt="">
              <div style="margin-bottom: 2px">{{ ChatItem.CompanyName }}</div>
            </div>
            <div id="item2" class="item2">
              <div v-for="(item,index) in ChatItem.Data" :key="index" :class="{ 'item2-left': item.SenderUserID !== UserID }">
                <img style="width: 34px;height: 34px;border-radius: 17px" :src="getAvator(item.Avator)" alt="">
                <div style="font-weight: bold;font-size: 14px;color: #014479">{{ item.Sender }}</div>
                <div style="font-size: 12px;color: #333333">{{ item.SentDate }}</div>
                <div class="input" :class="{ 'input-left': item.SenderUserID !== UserID }">{{ item.EmailContent }}</div>
              </div>
            </div>
            <div class="item3">
              <b-form-textarea style="width: 100%;height: 90px" :placeholder="placeholder" class="form-control" v-model="ListQuery.Content" id="Content"
                               @blur="checkReq" :class="{ error : Content}"/>
              <div style="color: red">{{Content}}</div>
            </div>
            <div class="item4">
              <div class="button hand" @click="handleSend">
                <img src="@/assets/image/market/send.png" alt="">
                <div>Send</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.ChatItem.CompanyName === ''" >
          <img class="btcnomessage" src="@/assets/image/market/btcnomessage.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalStore } from '@/utils/common'

export default {
  components: {},
  name: 'Message',
  data () {
    return {
      ChatQueue: [],
      ChatItem: {
        CompanyName: ''
      },
      placeholder: 'Message...',
      ListQuery: {
        Content: ''
      },
      Content: '',
      data: {},
      postEmail: {},
      chatItemEmail: {}
    }
  },
  computed: {
    UserID () {
      return getLocalStore().UserID
    }
  },
  mounted () {
    this.getWebChatList()
  },
  watch: {
    'ChatItem.CompanyName' (nval, oval) {
      if (nval !== oval) {
        // 滑到底部
        this.$nextTick(() => {
          const msg = document.getElementById('item2')
          if (msg) {
            msg.scrollTop = msg.scrollHeight
          }
        })
      }
    }
  },
  methods: {
    getWebChatList () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebChatList', { UserID: getLocalStore().UserID }).then(() => {
        this.ChatQueue = this.$store.getters.marketDict.Data
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleChat (item) {
      this.data.Sender = item.Receiver
      this.data.Receiver = item.Sender
      this.data.ReceiverUserID = item.SenderUserID

      item.IsRedDot = false
      this.getWebChatItem(item)
    },
    getWebChatItem (item) {
      this.$store.dispatch('SetLoadingStatus', true)
      if (item) {
        this.chatItemEmail = Object.assign({}, item)
      }
      this.$store.dispatch('GetWebChatItem', {
        IsTo: this.chatItemEmail.IsTo,
        SenderUserID: this.chatItemEmail.SenderUserID,
        ReceiverUserID: this.chatItemEmail.ReceiverUserID
      }).then(() => {
        this.ChatItem = this.$store.getters.marketItem

        if (!item) {
          this.$nextTick(() => {
            const msg = document.getElementById('item2')
            if (msg) {
              msg.scrollTop = msg.scrollHeight
            }
          })
        }
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    getAvator (path) {
      return path ? process.env.VUE_APP_STATIC + path : require('@/assets/image/person.png')
    },
    handleSend () {
      if (this.checkReq()) {
        this.postEmail.Sender = this.data.Sender
        this.postEmail.SenderUserID = getLocalStore().UserID
        this.postEmail.Receiver = this.data.Receiver
        this.postEmail.ReceiverUserID = this.data.ReceiverUserID
        this.postEmail.Content = this.ListQuery.Content
        this.postEmail.AuditState = 1
        this.$store.dispatch('SendFuturesEmail', this.postEmail).then(() => {
          this.ListQuery.Content = ''
          this.getWebChatItem(false)
        })
      }
    },
    checkReq () {
      if (!this.ListQuery.Content || !this.ListQuery.Content.replace(/\s*/g, '')) {
        this.Content = 'Please enter content.'
        return false
      } else {
        this.Content = ''
        return true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.box-company-info {
  width: 963px;
  height: 744px;
  background-color: #ffffff;
  border-radius: 20px;
  color: #535353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 30px  0 20px 30px;
}
.header{
  width: 100%;
  border-bottom: 1px solid #E6E6E6;
}
.header > div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 22px;
  margin: 25px 0 5px 45px;
  font-size: 14px;
}
.header > div > div{
  width: 34px;
  height: 1px;
  border-top: 1px solid #014479;
}

.body{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.no-message{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.body-left{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.body-left > div{
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #E6E6E6;
}

.body-right{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.body-right-body{
  width: 100%;
  height: 617px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #808080;
}
.item1{
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid #CCCCCC;
}
.item2{
  width: 100%;
  height: 368px;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.item2 > div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  min-height: 137px;
  margin-top: 10px;
  padding: 0 20px;
}
.item2-left{
  align-items: flex-start!important;
}
.input{
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 306px;
  font-size: 14px;
  border-radius: 12px;
  background-color: #4398CF;
  color: #FFFFFF;
  font-weight: bold;
  padding: 5px 10px;
  line-height: 21px;
  white-space: pre-wrap;
  word-wrap:break-word;
  word-break: break-all;
}
.input-left{
  background-color: #F2F2F2;
  color: #333333;
}

.item3{
  height: 90px;
}

.form-control{
  background-color: #F5F5F5!important;
  color: #4F5157;
  font-size: 14px;
  padding: 20px 40px;
  border: none;
}

.item4{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
}

.button{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 109px;
  height: 36px;
  font-size: 17px;
  font-weight: bold;
  padding: 5px 20px 5px 10px;
  margin: 20px 0 10px 0;
  background-color: #4398CF;
  color: #FFFFFF;
  border-radius: 5px;
}

.button > img{
  width: 25px;
  height: 21px;
}
.btcnomessage{
  height: 694px;
  width: 100%;
  border-bottom-right-radius: 20px ;
}

.red-point{
  position: relative;
}

.red-point::before{
  content: " ";
  border: 5px solid red;
  border-radius:5px;
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -5px;
}

</style>
