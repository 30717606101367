<template>
  <div class="box-company-info">
    <div class="tabbar-item-box">
      <div v-for="(item, index) in items" :key="index" class="hand" @click="handleClickTab(index)">
        <span>{{ item }}</span>
        <div class="divider" :class="{ active: current === index }"/>
      </div>
    </div>
    <div v-if="current === 0" class="eyes">
      <div v-if="eyesList.length !== 0" class="card-box">
        <btc-video-card-col v-for="(item,index) in eyesList" :key="index" :item="item" :class="{ ml: index % 5 !== 0 }" style="margin-top: 20px;" @click.native="handleVideoCard(index)"/>
      </div>
      <div v-if="eyesList.length === 0" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;;margin-top: 40px">
        <img src="@/assets/image/login/no-msg.png">
      </div>
    </div>

    <div v-if="current === 1" class="express">
      <div v-if="expressList.length !== 0" class="express-box">
        <favorite-express v-for="(item,index) in expressList" :key="index" :data="item" style="margin-top: 20px;"/>
      </div>
      <div v-if="expressList.length === 0" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;;margin-top: 40px">
        <img src="@/assets/image/login/no-msg.png">
      </div>
    </div>

    <div v-if="current === 2" class="express">
      <div v-if="analysisList.length !== 0" class="express-box">
        <favorite-express v-for="(item,index) in analysisList" :key="index" :data="item" style="margin-top: 20px;"/>
      </div>
      <div v-if="analysisList.length === 0" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;margin-top: 40px">
        <img src="@/assets/image/login/no-msg.png">
      </div>
    </div>
  </div>
</template>

<script>
import BtcVideoCardCol from '@/components/btc-video-card-col'
import FavoriteExpress from './favorite-express'
import { getLocalStore } from '@/utils/common'

export default {
  name: 'Favorite',
  components: {
    BtcVideoCardCol,
    FavoriteExpress
  },
  data () {
    return {
      current: 0,
      listQuery: {
        UserID: getLocalStore().UserID
      },
      list: [],
      eyesList: [],
      expressList: [],
      analysisList: [],
      items: ['BTC Eyes', 'BTC Express', 'BTC Analysis'],
      total: 0
    }
  },
  mounted () {
    this.getFavoriteList()
  },
  computed: {
    langs () {
      const newArr = [...this.data.Langs]
      if (newArr.length !== 0) {
        newArr.unshift('en')
      }
      return this.langIcon.filter(v => {
        return newArr.includes(v.lang)
      })
    },
    articles () {
      return this.data.Recommendation
    }
  },
  methods: {
    getFavoriteList () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebCollection', this.listQuery).then(() => {
        this.list = this.$store.getters.newsRows
        this.total = this.$store.getters.newsTotal

        this.eyesList = this.getFilterList(this.$t('message._beefeyes_en'))
        this.expressList = this.getFilterList(this.$t('message._exoress_en'))
        this.analysisList = this.getFilterList(this.$t('message._analysis_en'))

        console.log(this.expressList)

        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleVideoCard (index) {
      this.$router.push({ path: '/BTCEyes', query: { list: this.eyesList } })
    },
    handleClickTab (index) {
      this.current = index
    },
    getFilterList (type) {
      return this.list.filter(v => {
        return v.Type === type
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box-company-info {
  width: 963px;
  min-height: 534px;
  background-color: #ffffff;
  border-radius: 20px;
  color: #535353;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 30px  0 20px 30px;
}

.tabbar-item-box {
  height: 50px;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.tabbar-item-box > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding: 20px 10px 0 30px;
}
.divider {
  height: 2px;
  width: 40px;
  margin-top:2px;
}
.active {
  background-color: #024579;
}

.eyes {
  width: 100%;
}
.eyes > .card-box {
  display: flex;
  flex-flow: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 0 40px;
}
.ml {
  margin-left: 29px;
}

.express {
  width: 100%;
}
.express > .express-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 40px;
}
</style>
